import { push } from 'connected-react-router'

import { sessionConstants } from '../constants'
import {alertActions, recordsActions} from './index'
import { sessionServices } from '../services'

export const sessionActions = {
  create,
  destroy,
}

function create(params) {
  return dispatch => {
    dispatch(request())
    sessionServices.create(params).then(
      response => {
        const token = response.data.token
        localStorage.setItem('token', token)
        dispatch(success(token))
        dispatch(
          push({
            pathname: '/profile/dashboard',
            search: '?childTab=0',
          })
        )
      },
      error => {
        if (error.response) {
          const errors = error.response.data.errors
          if (errors) {
            dispatch(failure(errors))
            errors.forEach(e => {
              dispatch(alertActions.error(e))
            })
          }
        }
        localStorage.removeItem('token')
      }
    )
  }

  function request() {
    return { type: sessionConstants.LOGIN_REQUEST }
  }

  function success(token) {
    return { type: sessionConstants.LOGIN_SUCCESS, token }
  }

  function failure(errors) {
    return { type: sessionConstants.LOGIN_FAILURE, errors }
  }
}

function destroy() {
  return dispatch => {
    dispatch(request())
    sessionServices.destroy().then(
      response => {
        dispatch(success())
        localStorage.removeItem('token')
        localStorage.removeItem('record')
        dispatch(push('/'))
        dispatch(recordsActions.refresh(null))
      },
      error => {
        dispatch(success())
        localStorage.removeItem('token')
        localStorage.removeItem('record')
        dispatch(push('/'))
        dispatch(recordsActions.refresh(null))
      }
    )
  }

  function request() {
    return { type: sessionConstants.LOGOUT_REQUEST }
  }

  function success() {
    return { type: sessionConstants.LOGOUT_SUCCESS }
  }
}
