import React from 'react'
import {connect} from 'react-redux'
import ReactTooltip from 'react-tooltip'
import {Spinner} from '../../components/Spinner'
import {HelpCircle} from 'react-feather'

import {recordsActions} from '../../actions'
import {store} from '../../helpers'
import Child from '../../components/Profile/Child/Child'

export class Dashboard extends React.Component {
  componentDidMount() {
    const {dispatch} = store
    if (!this.props.record || !this.props.record.surveys) dispatch(recordsActions.get())
  }

  render() {
    const {record, recordLoading, recordError, location, firstName,} = this.props

    let tip = ''
    return (
      <div className="container">
        {recordLoading ? (
          <div className="text-center">
            <Spinner loading={recordLoading}/>
          </div>
        ) : recordError ? (
          <div>
            <p>
              Something went wrong loading your records, try refreshing the page to try again.
            </p>
          </div>
        ) : (
          <div>
            {tip && (
              <ReactTooltip
                id="tooltip"
                place="right"
                effect="solid"
                type="light"
                multiline
                className="description-tooltip"
              />
            )}
            {record && (
              <>
                <div>
                  <h1 className="d-inline-block mb-5">
                    Hello{' '}
                    <span className="h1">{firstName}</span>
                  </h1>
                  {/*<HelpCircle*/}
                  {/*  size={22}*/}
                  {/*  className="ml-2 accent-color-1"*/}
                  {/*  style={{cursor: 'pointer'}}*/}
                  {/*  data-tip={tip}*/}
                  {/*  data-for={'tooltip'}*/}
                  {/*/>*/}
                </div>
                <Child
                  record={record}
                  location={location}
                />
              </>
            )}
          </div>
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  const {firstName} = state.session
  const recordLoading = state.record.loading
  const recordError = state.record.error
  const record = state.record.record

  return {
    recordLoading,
    recordError,
    record,
    firstName,
  }
}

export default connect(mapStateToProps)(Dashboard)
