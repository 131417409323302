import React from 'react'

import { Personal } from '../../components/Profile/Settings/Personal'
import Password from '../../components/Profile/Settings/Password'
import { Delete } from '../../components/Profile/Settings/Delete'

export class Settings extends React.Component {
  render() {

    return (
      <div className="container">
        <div>
          <h1 className="mb-4">
            Profile
          </h1>
          <Personal {...this.props} />
          <Password {...this.props} />
          <Delete {...this.props} />
        </div>
      </div>
    )
  }
}

export default Settings
