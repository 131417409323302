import React from 'react'
import {connect} from 'react-redux'

import {store} from '../../helpers'
import {sessionActions} from '../../actions'
import {push} from "connected-react-router";

export function checkAuthentication(Component) {
  class CheckAuthentication extends React.Component {
    componentDidMount() {
      this.checkAuth(this.props)
    }

    componentWillReceiveProps(nextProps) {
      this.checkAuth(nextProps)
    }

    checkAuth(props) {
      const {dispatch} = store

      if (!props.isAuthenticated) {
        const token = localStorage.getItem('token')
        if (token) dispatch(sessionActions.destroy())
      } else {
        const token = localStorage.getItem('token')
        if (!token) {
          if (props.storeToken) {
            localStorage.setItem('token', props.storeToken)
            dispatch(push('/profile/dashboard?childTab=0'))
          } else dispatch(sessionActions.destroy())
        } else {
          dispatch(push('/profile/dashboard?childTab=0'))
        }
      }
    }

    render() {
      return <Component {...this.props} />
    }
  }

  function mapStateToProps(state) {
    return {
      isAuthenticated: state.session.isAuthenticated,
    }
  }

  return connect(mapStateToProps)(CheckAuthentication)
}
