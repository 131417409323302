import React from 'react'

import {disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks} from 'body-scroll-lock'
import ReactModal from 'react-modal'
import Modal from 'react-modal'
import {XCircle} from 'react-feather'
import {ClipLoader} from 'react-spinners'

import './Modal.css'

let timesLoaded = 0

class SurveyModal extends React.Component {
  targetElement = null

  constructor(props) {
    super(props)

    this.onLoad = this.onLoad.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  componentDidMount() {
    Modal.setAppElement('body')
    this.targetElement = document.querySelector('#surveyModal')
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks()
  }

  closeModal() {
    timesLoaded = 0
    this.props.onClose()
  }

  onLoad() {
    if (timesLoaded > 0) {
      timesLoaded = 0
      this.props.handleAction()
    } else timesLoaded++
  }

  calculateHeightForIOS() {
    const {survey} = this.props

    switch (survey.instrument) {
      case 'pbq_sports_enrollment':
        return '1568px'
      case 'pbq_demographics':
        return '1826px'
      case 'pbq_injury_concussion_history':
        return '3101px'
      case 'pbq_medical_history':
        return '6485px'
      case 'pbq_physical_activity_participation':
        return '17500px'
      case 'ostrc':
        return '1188px'
      case 'cdrisk10child':
        return '1283px'
      case 'sdqparent':
      case 'sdqchild':
        return '1914px'
      case 'pedsqlchild':
      case 'pedsqlparent':
        return '2325px'
      case 'promis':
        return '1427px'
      case 'ipqrchild':
      case 'ipqrparent':
        return '765px'
      case 'baschild':
      case 'basparent':
        return '1438px'
      case 'briq':
        return '766px'
      case 'parent_additional_info':
        return '1553px'
      case 'ssq':
        return '1238px'
      default:
        return '92%'
    }
  }

  render() {
    const {showModal, link} = this.props
    const source = link ? link : null
    const isIOS = /iPhone|iPod|iPad/i.test(navigator.userAgent)

    let height = null
    if (isIOS) height = this.calculateHeightForIOS()
    showModal
      ? disableBodyScroll(this.targetElement)
      : enableBodyScroll(this.targetElement)

    return (
      <div id="surveyModal">
        <ReactModal
          style={{
            overlay: {backgroundColor: 'rgba(33, 33, 33, 0.85)'},
            content: {
              maxWidth: 800,
              width: '80%',
              margin: 'auto',
              backgroundColor: 'rgba(0, 0, 0, 0)',
              border: 'none',
            },
          }}
          isOpen={showModal}
          shouldCloseOnEsc={true}
          shouldReturnFocusAfterClose={true}
          closeTimeoutMS={200}
          onRequestClose={this.closeModal}
        >
          {
            source ? (
              <XCircle
                size={40}
                onClick={this.closeModal}
                style={{cursor: 'pointer', color: '#ffffff', float: 'right'}}
              />
            ) : null
          }
          {
            source ? (
                <iframe
                  title="survey"
                  src={source}
                  id="surveyFrame"
                  onLoad={this.onLoad}
                  style={{
                    border: 0,
                    height: isIOS ? height : '92%',
                    width: '100%',
                    paddingTop: '1rem',
                    overflow: 'auto',
                  }}
                />
              ) :
              <span style={{position: 'fixed', top: '50%', left: '50%'}}>
                <ClipLoader loading={true} size={50} color="#fff"/>
              </span>
          }
        </ReactModal>
      </div>
    )
  }
}

export default SurveyModal
