import axios, { CancelToken } from 'axios'
import { push } from 'connected-react-router'

import { store } from './store'
import { alertActions, sessionActions } from '../actions'


class AxiosInstance {
  constructor() {
    this.apiInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': process.env.REACT_APP_UI_URL,
      },
    })

    this.apiInstance.interceptors.request.use((config) => {
      const token = localStorage.getItem('token')
      config.headers.Authorization = token ? `Bearer ${token}` : ''
      config.p0 = performance.now()

      return config
    })

    this.apiInstance.interceptors.response.use(async response => {
        const minimumDelay = 350
        const latency = performance.now() - response.config.p0
        const shouldNotDelay = minimumDelay < latency

        if (shouldNotDelay) {
          return response
        }

        const remainder = minimumDelay - latency
        const [responseWithDelay] = await Promise.all([
          response,
          new Promise((resolve) => setTimeout(resolve, remainder)),
        ])

        return responseWithDelay
      },
      error => {

        if (error.response.statusText === 'UNAUTHORIZED') {

        }

        if (
          error.request &&
          error.request.responseType === 'blob' &&
          error.response.data instanceof Blob &&
          error.response.data.type &&
          error.response.data.type.toLowerCase().indexOf('json') !== -1
        ) {
          return new Promise((resolve, reject) => {
            let reader = new FileReader()
            reader.onload = () => {
              error.response.data = JSON.parse(reader.result)
              if (!axios.isCancel(error)) this.errorHandler(error)
              resolve(Promise.reject(error))
            }

            reader.onerror = () => {
              reject(error)
            }

            reader.readAsText(error.response.data)
          })
        }

        if (!axios.isCancel(error)) this.errorHandler(error)
        return Promise.reject(error)
      }
    )
  }

  create = async ({ url, params } = {}, kwargs = {}) => {
    try {
      const response = await this.apiInstance.post(url, params, kwargs)
      return response
    }
    catch (error) {
      return Promise.reject(error)
    }
  }

  get = async ({ url, params, cancel } = {}) => {
    const finalURL = params ? `${url}?${params}` : url
    try {
      const response = await this.apiInstance.get(finalURL, { cancelToken: cancel ? new CancelToken(c => (cancel.exec = c)) : null })
      return response
    }
    catch (error) {
      return Promise.reject(error)
    }
  }

  update = async ({ url, params, extra } = {}) => {
    const finalURL = extra ? `${url}/${extra}` : url
    try {
      const response = await this.apiInstance.put(finalURL, params)
      return response
    }
    catch (error) {
      return Promise.reject(error)
    }
  }

  patch = async ({ url, params, extra } = {}) => {
    const finalURL = extra ? `${url}/${extra}` : url
    try {
      const response = await this.apiInstance.patch(finalURL, params)
      return response
    }
    catch (error) {
      return Promise.reject(error)
    }
  }

  destroy = async ({ url, params } = {}) => {
    const finalURL = params ? `${url}?${params}` : url
    try {
      const response = await this.apiInstance.delete(finalURL)
      return response
    }
    catch (error) {
      return Promise.reject(error)
    }
  }

  getFile = async ({ url, params } = {}) => {
    const finalURL = params ? `${url}?${params}` : url
    try {
      const response = await this.apiInstance.get(finalURL, { responseType: 'blob' })
      return response
    }
    catch (error) {
      return Promise.reject(error)
    }
  }

  errorHandler = (error) => {
    const { dispatch } = store
    if (error.response && error.response.status) {
      if (error.response && error.response.data.msg === 'User is not authorized')
      {
        dispatch(sessionActions.destroy())
        dispatch(push('/'))
      }
      else if (error.response.data && 'errors' in error.response.data) {
        const errors = error.response.data.errors

        errors.forEach(e => {
          dispatch(alertActions.error(e))
        })
      }
    } else dispatch(alertActions.error("Server error"))
  }
}

export const axiosInstance = new AxiosInstance()