import React from 'react'

import {store} from '../../../helpers/index'
import {userServices} from '../../../services'
import {alertActions, userActions} from '../../../actions'


export class Personal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {first_name: '', last_name: '', phone_number: '', notify: '', frequency: ''}

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    const {dispatch} = store

    userServices.get().then(
      response => {
        const data = response.data.data

        this.setState({
          first_name: data.first_name ? data.first_name : '',
          last_name: data.last_name ? data.last_name : '',
          phone_number: data.phone_number ? data.phone_number : '',
          notify: data.notify ? parseInt(data.notify.value, 10) : '',
          frequency: data.frequency ? parseInt(data.frequency.value, 10) : '',
          created: data.created ? data.created : ''
        })
      },
      error => {
        if (error.response) {
          const errors = error.response.data.errors
          if (errors) {
            errors.forEach(e => {
              dispatch(alertActions.error(e))
            })
          }
        }
      }
    )
  }

  handleChange(event) {
    const {name, value} = event.target

    this.setState({
      [name]: value,
    })
  }

  handleSubmit(event) {
    event.preventDefault()

    const {first_name, last_name, phone_number, frequency, notify} = this.state
    const {dispatch} = store

    dispatch(userActions.update({first_name, last_name, phone_number, notify, frequency}))
  }

  render() {
    const {first_name, last_name, phone_number, notify} = this.state

    return (
      <div className="mt-5 mb-5">
        <h5>
          Profile
        </h5>
        <form onSubmit={this.handleSubmit}>
          <div className="form-group row">
            <label className="col-lg-3 col-form-label">
              First name
            </label>
            <div className="col-lg-3 mb-2">
              <input
                type="text"
                className="form-control"
                name="first_name"
                value={first_name}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-lg-3 col-form-label">
              Last name
            </label>
            <div className="col-lg-3 mb-2">
              <input
                type="text"
                className="form-control"
                name="last_name"
                value={last_name}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-lg-3 col-form-label">
              Cell phone number
            </label>
            <div className="col-lg-3 mb-2">
              <input
                type="tel"
                className="form-control"
                name="phone_number"
                placeholder={'403 123 1234'}
                value={phone_number}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-lg-3 col-form-label">
              How would you like to be notified?
            </label>
            <div className="col-lg-3 mb-2">
              <select
                id="notify"
                className="form-control"
                name="notify"
                value={notify}
                onChange={this.handleChange}
              >
                <option value={1}>
                  email
                </option>
                <option value={2}>
                  text
                </option>
                <option value={0}>
                  both
                </option>
              </select>
            </div>
          </div>
          <div className="form-group">
            <button type="submit" className="btn btn-primary mt-2">
              Save
            </button>
          </div>
        </form>
      </div>
    )
  }
}
