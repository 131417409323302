import React from 'react'
import {push} from 'connected-react-router'
import qs from 'qs'

import {store} from '../../../helpers/index'
import {Surveys} from '../../Surveys'
import './Child.css'

let cancel = {exec: null}

class Child extends React.Component {
  constructor(props) {
    super(props)

    let activeTab = 'baseline'

    activeTab = props.record && props.record['neck_dizziness_portal_id'] ? 'neck_dizziness' : activeTab
    activeTab = props.record && props.record['headache_neck_dizziness_portal_id'] ? 'headache_neck_dizziness' : activeTab
    activeTab = props.record && props.record['headache_portal_id'] ? 'headache' : activeTab

    this.state = {
      activeTab: activeTab,
    }
  }

  componentDidMount() {
    const {location} = this.props

    if (location) {
      const search = qs.parse(location.search.slice(1))

      if (search && search.contentTab) {
        this.setState({
          activeTab: search.contentTab,
        })
      }
    }
  }

  componentWillUnmount() {
    if (cancel.exec) cancel.exec()
  }

  static getDerivedStateFromProps(props, state) {
    const {location} = props

    if (location) {
      const search = qs.parse(location.search.slice(1))

      if (search && search.contentTab) {
        state.activeTab = search.contentTab
      }
    }

    return state
  }

  dynamicHeightCalc() {
    const {record} = this.props
    let height = 800

    const x = record && record.surveys ? record.surveys['baseline'] : null

    if (x && x.length === 3) {
      if (x[2]['surveys'].length > 5) {
        let i = x[2]['surveys'].length
        i = i - 5
        height = height + i * 80
        height = String(height) + 'px'
      }
    } else if (x && x.length === 4) {
      height = 1000
      let i = Math.max(x[2].length, x[3].length)
      if (i > 5) {
        i = i - 5
        height = height + i * 80
        height = String(height) + 'px'
      }
    }
    return height
  }

  handleTabChange = tab => {
    const {dispatch} = store
    const {location} = this.props

    if (location) {
      const search = qs.parse(location.search.slice(1))

      if (search && search.childTab) {
        dispatch(
          push({
            pathname: '/profile/dashboard',
            search: '?childTab=' + search.childTab + '&contentTab=' + tab,
          })
        )
        this.setState({
          activeTab: tab,
        })
      }
    }
  }

  render() {
    const {activeTab} = this.state
    const {record} = this.props
    const surveyTypes = record && record.surveys ? record.surveys[activeTab] : null

    const dynamicGridHeight = this.dynamicHeightCalc()
    return (
      <div>
        <div className="row mb-2">
          <ul
            className="col-lg-12 col-sm-12 col-12 nav nav-tabs"
            style={{marginLeft: '14px'}}
          >
            {
              record && record['neck_dizziness_portal_id'] && (
                <li className="nav-item">
              <span
                key="neck_dizziness"
                className={
                  activeTab === 'neck_dizziness' ? 'nav-link active' : 'nav-link'
                }
                onClick={() => this.handleTabChange('neck_dizziness')}
              >
                Neck Pain/Dizziness
              </span>
                </li>
              )
            }
            {
              record && record['headache_neck_dizziness_portal_id'] && (
                <li className="nav-item">
              <span
                key="headache_neck_dizziness"
                className={
                  activeTab === 'headache_neck_dizziness' ? 'nav-link active' : 'nav-link'
                }
                onClick={() => this.handleTabChange('headache_neck_dizziness')}
              >
                Headache & Neck Pain/Dizziness
              </span>
                </li>
              )
            }
            {
              record && record['headache_portal_id'] &&
              (
                <li className="nav-item">
              <span
                key="headache"
                className={
                  activeTab === 'headache' ? 'nav-link active' : 'nav-link'
                }
                onClick={() => this.handleTabChange('headache')}
              >
                Headache
              </span>
                </li>
              )
            }
            <li className="nav-item">
              <span
                key="baseline"
                className={
                  activeTab === 'baseline' ? 'nav-link active' : 'nav-link'
                }
                onClick={() => this.handleTabChange('baseline')}
              >
                Baseline
              </span>
            </li>
          </ul>
        </div>
        <div
          className={activeTab === 'baseline' ? 'grid' : 'grid'}
          style={{height: dynamicGridHeight}}
        >
          {surveyTypes &&
          surveyTypes.length > 0 ?
            surveyTypes.sort((a, b) => (a['order'] > b['order'] ? 1 : -1)).map((surveyType, index) => {
              return (
                <>
                  <div className="grid-surveys" key={index}>
                    <Surveys
                      key={index}
                      surveyType={surveyType.type_name}
                      surveys={surveyType.surveys}
                      record={record}
                      surveyPath={activeTab}
                      activeTab={activeTab}
                    />
                  </div>
                </>
              )
            }) : <h5 style={{marginTop: '3rem'}}>
              No surveys yet available
            </h5>}
        </div>
      </div>
    )
  }
}

export default Child