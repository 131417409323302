import React from 'react'

import SurveyModal from '../Modals/SurveyModal'
import {Status} from './Status'
import {StatusIcon} from './StatusIcon'
import {store} from '../../helpers/index'
import {mobileSurveys} from '../../constants'
import {recordsActions, alertActions} from '../../actions'
import {surveyServices} from '../../services/survey.services'
import {surveyLinkServices} from "../../services";

class SurveyItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showSurveyModal: false,
      surveyLink: null
    }

    this.handleAction = this.handleAction.bind(this)
  }

  closeModal = () => {
    this.setState({
      showSurveyModal: false
    })
  }

  handleAction() {
    const {record, survey, surveyType, surveyPath} = this.props
    const {dispatch} = store
    const baseline_portal_id = record.baseline_portal_id
    const headache_neck_dizziness_portal_id = record.headache_neck_dizziness_portal_id
    const headache_portal_id = record.headache_portal_id
    const neck_dizziness_portal_id = record.neck_dizziness_portal_id


    const j = record.surveys[surveyPath].findIndex(i => i.type_name === surveyType)

    if (j >= 0) {
      const m = record.surveys[surveyPath][j].surveys.findIndex(i => i.name === survey.name)

      if (m >= 0) {
        const portal_id = survey.group === 'baseline' ? baseline_portal_id :
          survey.group === 'headache_neck_dizziness' ? headache_neck_dizziness_portal_id :
            survey.group === 'headache' ? headache_portal_id :
              survey.group === 'neck_dizziness' ? neck_dizziness_portal_id :
                null // TODO: add for randomized arms
        const params = {survey: survey, portal_id: portal_id}
        surveyServices.update(params).then(
          response => {
            record.surveys[surveyPath][j].surveys[m] = response.data.survey
            dispatch(alertActions.success('Your survey response has been submitted'))
            dispatch(recordsActions.refresh(record))
          },
          error => {
            if (error.response) {
              const errors = error.response.data.errors
              if (errors) {
                errors.forEach(e => {
                  dispatch(alertActions.error(e))
                })
              }
            }
          }
        )
      }
    }
    this.closeModal()
  }

  handleSurveyClick = () => {
    const {survey, surveyPath} = this.props
    this.setState({surveyLink: null})
    console.log(surveyPath)
    if (survey.availability && !survey.completed) {
      this.setState({showSurveyModal: true})

      const params = `arm=${surveyPath}&instrument=${survey['instrument']}&event=${survey['event']}`

      surveyLinkServices.get(params).then(response => {
        this.setState({surveyLink: response.data['link']})
      })

    }
  }

  render() {
    const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent)
    const {showSurveyModal, surveyLink} = this.state
    const {survey, id, record, surveyPath, surveyType} = this.props

    return (
      <div className="col-lg-10">
        <SurveyModal
          showModal={showSurveyModal}
          baseline_portal_id={record.baseline_portal_id}
          link={surveyLink}
          survey={survey}
          surveyType={surveyType}
          surveyPath={surveyPath}
          handleAction={this.handleAction}
          onClose={this.closeModal}
        />
        <div
          className="card"
          style={{width: '100%', borderRadius: 0, cursor: 'pointer'}}
          id={id}
          onClick={this.handleSurveyClick}
        >
          <div className="card-body" style={{padding: '0.6rem'}} id={id}>
            <div className="row">
              <div
                style={{
                  marginLeft: '1.3rem',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <StatusIcon survey={survey}/>
                <div style={{marginLeft: '1.3rem'}}>
                  <h5 style={{margin: 0}} id={id}>
                    {isMobile && mobileSurveys[survey.name]
                      ? mobileSurveys[survey.name]
                      : survey.name}
                  </h5>
                  <Status survey={survey}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SurveyItem
