import React, { useState } from 'react'
import { Transition, animated } from 'react-spring/renderprops'
import { Collapse } from 'reactstrap'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'

import Login from '../../components/Account/Login'
import homeJPG from '../../images/Home/home.jpg'
import homeWEBP from '../../images/Home/home.webp'
import smartLogo from '../../images/ShredLogos/smart_title.png'
import dot1 from '../../images/Misc/siprc_dot1.png'
import dot2 from '../../images/Misc/siprc_dot2.png'
import dot3 from '../../images/Misc/siprc_dot3.png'
import dot4 from '../../images/Misc/siprc_dot4.png'
import './home.css'

const items = [
  { image: dot1, key: 1 },
  { image: dot2, key: 2 },
  { image: dot3, key: 3 },
  { image: dot4, key: 4 },
]

export const Home = props => {
  const { isAuthenticated } = props
  const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent)

  const [isOneOpen, setIsOneOpen] = useState(false)
  const [isTwoOpen, setIsTwoOpen] = useState(false)
  const [isThreeOpen, setIsThreeOpen] = useState(false)

  // This got messy...
  const toggle = selector => {
    if (selector === 'one' && isOneOpen && !isTwoOpen && !isThreeOpen) {
      setIsOneOpen(false)
      return
    } else if (selector === 'two' && isTwoOpen && !isOneOpen && !isThreeOpen) {
      setIsTwoOpen(false)
      return
    } else if (
      selector === 'three' &&
      isThreeOpen &&
      !isOneOpen &&
      !isTwoOpen
    ) {
      setIsThreeOpen(false)
      return
    } else if (!isOneOpen && !isTwoOpen && !isThreeOpen) {
      if (selector === 'one') setIsOneOpen(true)
      else if (selector === 'two') setIsTwoOpen(true)
      else setIsThreeOpen(true)
      return
    } else {
      if (selector === 'one') {
        setIsOneOpen(!isOneOpen)
        setIsTwoOpen(false)
        setIsThreeOpen(false)
      } else if (selector === 'two') {
        setIsTwoOpen(!isTwoOpen)
        setIsOneOpen(false)
        setIsThreeOpen(false)
      } else {
        setIsThreeOpen(!isThreeOpen)
        setIsOneOpen(false)
        setIsTwoOpen(false)
      }
    }
  }

  const getDots = data => {
    const items = []
    data.map(item =>
      items.push(
        <img
          key={item.key}
          className="dots dots-mobile m-2"
          src={item.image}
          alt="dot_logo"
        />
      )
    )
    return (
      <Transition
        native={true}
        items={items}
        keys={item => item.key}
        from={{
          transform: 'translate3d(0,-50%,0)',
          opacity: '0',
        }}
        enter={{
          transform: 'translate3d(0,0,0)',
          opacity: '1',
        }}
        trail={175}
      >
        {item => props => <animated.div style={props}>{item}</animated.div>}
      </Transition>
    )
  }

  return (
    <div style={{ position: 'relative' }}>
      <picture>
        <source srcSet={homeWEBP} type="image/webp" />
        <source srcSet={homeJPG} type="image/jpeg" />
        <img
          className="home-cover"
          alt="background"
          src={homeJPG}
          style={{
            width: '100%',
            objectFit: 'cover',
            borderRadius: `0px`,
          }}
        />
      </picture>
      <div
        className="container"
        style={{
          position: 'absolute',
          left: '52%',
          top: '40%',
          transform: 'translate(-55%, -15vh)',
          zIndex: 1,
        }}
      >
        <div className="row">{getDots(items)}</div>
        <div className="row">
          <div
            className="col-lg-7 p-5 mt-2 card"
            style={{
              boxShadow:
                '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)',
            }}
          >
            {!isMobile && (
              <div className="mb-4">
                <h1 className="m-0">
                  <div
                    className="text-primary"
                    style={{ display: 'absolute', bottom: '0px', fontSize: '40px' }}
                  >
                    <img
                      className="dots dots-mobile"
                      style={{ width: '75%', paddingBottom: '10px', paddingRight: '0.5rem' }}
                      src={smartLogo}
                      alt="shredBlack"
                    />
                  </div>
                </h1>
                <span className="lead muted-color">
                  Studying the most effective concussion treatments for adolescents who have had recent concussions
                </span>
              </div>
            )}
            {!isAuthenticated && <Login loginStyle={!isMobile && 'home'} />}
          </div>
        </div>
      </div>
    </div>
  )
}
