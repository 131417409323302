import React from 'react'

import { Section } from '../Profile/SectionHeading/Section'
import SurveyList from './SurveyList'

export const Surveys = props => {
  const {surveys, surveyType, record, surveyPath} = props

  return (
    <div>
      <Section heading={surveyType}/>
      <SurveyList
        surveys={surveys}
        surveyType={surveyType}
        record={record}
        surveyPath={surveyPath}
      />
    </div>
  )
}
