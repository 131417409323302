export const recordConstants = {
  RECORD_REQUEST: 'RECORD_REQUEST',
  RECORD_SUCCESS: 'RECORD_SUCCESS',
  RECORD_FAILURE: 'RECORD_FAILURE',
  RECORD_REFRESH: 'RECORD_REFRESH',
}

export const sessionConstants = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',

  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',

  SESSION_UPDATE_SUCCESS: 'SESSION_UPDATE_SUCCESS',
}

export const mobileSurveys = {
  'Pre-Season Questionnaire': '?',
}
