import React from 'react'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'

import { store } from '../../helpers'

export class About extends React.Component {
  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(event) {
    event.preventDefault()
    const { dispatch } = store

    dispatch(push('/signup'))
  }

  render() {
    const { isAuthenticated } = this.props

    return (
      <div>
        <div className="container mb-5">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-9 col-sm-12">
                  <h2 className="heading mb-5">
                    an
                    <span className="text-primary">
                      {" introduction"}
                    </span>
                  </h2>
                </div>
              </div>
              <h5 className="mb-4">
                The SMART Concussion Trial
              </h5>
              <p className="text-justify mb-5">
                Thank you for your participation in the SMART Concussion Trial (Symptoms Management vs Alternative Randomized Treatment of Concussion).
                In North America youth account for over half of more than three million concussions. Studies that examine treatment for concussion are
                sparse and most focus on single treatments and many in adolescents with ongoing, persistent symptoms. The primary objective of the
                SMART Concussion Trial is to determine whether symptom-specific treatment acutely following concussion in 13-19 year old's is superior
                to a multimodal treatment program.
                <br/>
                <br/>
              </p>
              <h5 className="mb-4">
                ETHICS APPROVAL
              </h5>

              <p className="mb-5 text-justify">The SMART Concussion Trial has been approved by the Research Ethics Board at the University of Calgary (REB21-1045).
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { isAuthenticated } = state.session

  return {
    isAuthenticated,
  }
}

export default connect(mapStateToProps)(About)
