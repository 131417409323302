import {axiosInstance} from '../helpers'


const url = '/signup-request'

const create = params => axiosInstance.create({url: url, params: params})

const update = (token, params) => axiosInstance.update({url: `${url}/${token}`, params: params})

const get = token => axiosInstance.get({url: `${url}/${token}`, params: null})

const destroy = type => axiosInstance.destroy({url: `${url}?${type}`, params: null})


export const signUpRequestServices = {
  create,
  update,
  get,
  destroy,
}
