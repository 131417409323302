import { recordConstants } from '../constants'

const initialState = {}

export function record(state = initialState, action) {
  switch (action.type) {
    case recordConstants.RECORD_REQUEST:
      let storedRecord = localStorage.getItem('record')
      let record = ''
      if (storedRecord) record = JSON.parse(storedRecord)

      return {
        loading: !record,
        error: false,
        record: record
      }
    case recordConstants.RECORD_SUCCESS:
    case recordConstants.RECORD_REFRESH:
      return {
        loading: false,
        error: false,
        record: {...action.record}
      }
    case recordConstants.RECORD_FAILURE:
      return {
        loading: false,
        error: true
      }
    default:
      return state
  }
}
