import React from 'react'
import { push } from 'connected-react-router'

import {store} from '../../helpers'
import {FormErrors} from '../../components/FormErrors'
import {Spinner} from "../../components/Spinner"
import {signUpRequestServices} from '../../services'
import ReactTooltip from "react-tooltip";
import {alertActions} from "../../actions";

let cancel = {exec: null}

class SignUp extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            phoneNumber: '',
            password: '',
            password_confirmation: '',
            formErrors: {password: '', password_confirmation: ''},
            passwordValid: false,
            passwordConfirmationValid: false,
            formValid: false,
            pageLoading: true,
            pageError: null,
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount() {
        const {token} = this.props.match.params

        signUpRequestServices.get(token).then(
            response => {
                this.setState({
                    pageLoading: false,
                    email: response.data.data.email,
                    phoneNumber: response.data.data.phone_number
                })
            },
            error => {
                this.setState({
                    pageLoading: false,
                    pageError: error.response.data.error
                })
            }
        )
    }

    handleChange(event) {
        const {name, value} = event.target
        this.setState(
            {
                [name]: value,
            },
            () => {
                this.validateField(name, value)
            }
        )
    }

    handleSubmit(event) {
        event.preventDefault()
        const {formValid, password, password_confirmation, email, phoneNumber} = this.state
        const {dispatch} = store

        if (formValid) {
            const {token} = this.props.match.params
            const params = {
                email: email,
                phone_number: phoneNumber,
                password: password,
                password_confirmation
            }

            signUpRequestServices.update(token, params).then(response => {
                dispatch(alertActions.success(response.data.data))
                dispatch(push('/'))
            }, error => {
                if (error.response && error.response.data) {
                    dispatch(alertActions.error(error.response.data.error))
                }
            })
        }
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors
        let {passwordValid, passwordConfirmationValid} = this.state
        let {password, password_confirmation} = this.state

        switch (fieldName) {
            case 'password':
                if (value !== '') {
                    passwordValid = value.length >= 6
                    fieldValidationErrors.password = passwordValid ? '' : 'Password must be 6 characters long.'
                    if (passwordValid) {
                        passwordConfirmationValid =
                            value === password_confirmation &&
                            password_confirmation.length > 0
                        fieldValidationErrors.password_confirmation = passwordConfirmationValid ? '' : 'Passwords do not match.'
                    }
                } else {
                    passwordValid = false
                    passwordConfirmationValid = false
                    fieldValidationErrors.password = ''
                    fieldValidationErrors.password_confirmation = ''
                }
                break
            case 'password_confirmation':
                if (value !== '') {
                    passwordConfirmationValid = value === password
                    fieldValidationErrors.password_confirmation = passwordConfirmationValid ? '' : 'Passwords do not match.'
                } else passwordConfirmationValid = false
                if (passwordValid)
                    fieldValidationErrors.password_confirmation = passwordConfirmationValid ? '' : 'Passwords do not match.'
                else fieldValidationErrors.password_confirmation = ''
                break
            default:
                break
        }

        this.setState({passwordValid: passwordValid, passwordConfirmationValid: passwordConfirmationValid, formErrors: fieldValidationErrors,}, this.validateForm)
    }

    validateForm() {
        const {passwordValid, passwordConfirmationValid} = this.state
        this.setState({formValid: passwordValid && passwordConfirmationValid})
    }

    render() {
        const {formErrors, email, phoneNumber, pageLoading, pageError} = this.state


        return (
            pageLoading ?
                <div className="text-center">
                    <Spinner loading={pageLoading}/>
                </div>
                : pageError ?
                <div className='text-center'>
                    <h3>Could not open signup form</h3><br/>
                    <h4 style={{color: '#e30c00'}}>{pageError}</h4>
                </div>
                :
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-lg-9">
                            <h2 className="heading">
                                Participant
                                <span>&nbsp;</span>
                                <span className="text-primary">Sign Up</span>
                                <ReactTooltip
                                    id="tooltip"
                                    place="right"
                                    effect="solid"
                                    type="light"
                                    multiline
                                    className="description-tooltip"
                                />
                            </h2>
                            <div className="mt-2 mb-2">
                                <FormErrors formErrors={formErrors}/>
                            </div>
                            <form name="form" onSubmit={this.handleSubmit}>
                                <div className="form-group row">
                                    <label className="col-lg-3 col-form-label text-lg-right text-md-center">
                                        Email
                                    </label>
                                    <div className="col-lg-6 mb-2">
                                        <input
                                            disabled
                                            type="text"
                                            className="form-control"
                                            name="email"
                                            value={email}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-lg-3 col-form-label text-lg-right text-md-center">
                                        Cell phone Number
                                    </label>
                                    <div className="col-lg-6 mb-2">
                                        <input
                                            disabled
                                            type="tel"
                                            className="form-control"
                                            name="phone_number"
                                            value={phoneNumber}
                                            placeholder={'403 123 1234'}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row ">
                                    <label className="col-lg-3 col-form-label text-lg-right text-md-center">
                                        Password
                                    </label>
                                    <div className="col-lg-6 mb-2">
                                        <input
                                            type="password"
                                            className="form-control"
                                            name="password"
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-lg-3 col-form-label text-lg-right text-md-center">
                                        Confirm Password
                                    </label>
                                    <div className="col-lg-6 mb-2">
                                        <input
                                            type="password"
                                            className="form-control"
                                            name="password_confirmation"
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <button
                                    type="submit"
                                    name="submit"
                                    className="btn btn-primary mt-5 mb-2"
                                    disabled={!this.state.formValid}
                                >
                                    Submit
                                </button>
                                <div>
                                  <span
                                      className="btn-custom"
                                      onClick={this.toggleLoginModal}
                                      style={{fontSize: '14px'}}
                                  >
                                  </span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
        )
    }
}

export default SignUp
