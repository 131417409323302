import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from "react-redux"

import smart from '../../images/ShredLogos/smart_full.png'
import './Navbar.css'

class Navbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      logoHoverOver: true,
    }
  }

  render() {
    const { isAuthenticated } = this.props
    const { logoHoverOver } = this.state

    return (
      <nav className="navbar navbar-expand-lg" style={{ height: '75px', backgroundColor: "#3d3a3a", borderTop: '1px solid #e30c00'}}>
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img
              className="logo logo-padding"
              alt="shred logo"
              src={logoHoverOver ? smart : smart}
              onMouseOver={() => this.setState({ logoHoverOver: false })}
              onMouseOut={() => this.setState({ logoHoverOver: true })}
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          ></button>
          <div className="flex-row-reverse" id="navebarSupportedContent">
            <ul className="navbar-nav">
              {isAuthenticated && (
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to={{
                      pathname: '/profile/dashboard',
                      search: '?childTab=0',
                    }}
                  >
                    my home
                  </Link>
                </li>
              )}
              <li className="collapse navbar-collapse nav-item">
                <Link className="nav-link" to="/about">
                  about
                </Link>
              </li>
              <li className="collapse navbar-collapse nav-item">
                <Link className="nav-link" to="/contact-us">
                  contact us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    )
  }
}

function mapStateToProps(state) {
  const { isAuthenticated } = state.session

  return {
    isAuthenticated,
  }
}

export default connect(mapStateToProps)(Navbar)
