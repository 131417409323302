import React from 'react'

import { Mail } from 'react-feather'
import { Phone } from 'react-feather'


export const ContactUs = () => (
  <div className="container mt-4 mb-4">
    <div className="row">
      <div className="col-lg-12">
        <h2 className="heading mb-5">
          contact{' '}
          <span className="text-primary">
            us
          </span>
        </h2>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-12">
        <h5 className="mb-4">
          HOW TO CONTACT US:
        </h5>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-6">
        <address className="bg-light p-3">
          Research Coordinator
          <br />
          4<sup>th</sup> floor, ACHRI, B4-510-11
          <br />
          Alberta Children's Hospital
          <br />
          28 Oki Drive NW
          <br />
          T3B 6A8
          <br/>
          <br />
          <br />
          <br />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Mail className="mr-2" />
            smartconcussion@ucalgary.ca
            <br />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Phone className="mr-2" />
            403.671.2852
          </div>
        </address>
      </div>
      <div className="col-lg-6">
        <address className="bg-light p-3">
          Principal Investigator: Keith Yeates
          <br />
          Faculty of Psychology
          <br />
          University of Calgary
          <br />
          2500 University Drive NW
          <br />
          Calgary, AB T2N 1N4
          <br />
          <br />
          <br />
          <br />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Mail className="mr-2" />
            kyeates@ucalgary.ca
            <br />
          </div>
        </address>
      </div>
    </div>
  </div>
)
